import groq from 'groq'

import { blogPostWithoutBodyFragment } from './blog'
import {
  complexPortableTextFragment,
  simplePortableTextFragment,
} from './content'
import { imageFragment } from './image'
import { productFragment } from './product'
import { newsletterFragment } from './site'
import { muxVideoFragment, videoFragment } from './video'

const freeformBlockFragment = groq`
  ...,
  content[] {
    ${complexPortableTextFragment}
  },
`

const accordionsBlockFragment = groq`
  items[] {
    ...,
    content[] {
      ${simplePortableTextFragment}
    },
  },
`

const productCardBlockFragment = groq`
  product->{
    ${productFragment}
  },
`

const signupFormStringsFragment = groq`
  signupTitle,
  signupFirstName,
  signupFirstNamePlaceholder,
  signupFirstNameMissing,
  signupLastName,
  signupLastNamePlaceholder,
  signupLastNameMissing,
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  signupErrorMessage[] {
    ${complexPortableTextFragment}
  },
  signupSubmit,
  signupLoginLink,
`

const signupFormBlockFragment = groq`
  active,
  "signupFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${signupFormStringsFragment}
  },
`

const loginFormStringsFragment = groq`
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  signupPassword,
  signupPasswordPlaceholder,
  signupPasswordMissing,
  loginTitle,
  loginSubmit,
  loginSignupLinkText,
  loginPasswordRecoveryLinkText,
  loginErrorMessage[] {
    ${complexPortableTextFragment}
    },
    loginFailedMessage[] {
      ${complexPortableTextFragment}
      },
`

const loginFormBlockFragment = groq`
  active,
  "loginFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${loginFormStringsFragment}
  },
`

const passwordRecoveryFormStringsFragment = groq`
  signupEmail,
  signupEmailPlaceholder,
  signupEmailMissing,
  passwordRecoveryErrorMessage[] {
    ${complexPortableTextFragment}
  },
  passwordRecoverySuccessMessage[] {
    ${complexPortableTextFragment}
  },
  passwordRecoveryTitle,
  passwordRecoverySubmit,
  passwordRecoveryLoginLink,
`

const passwordRecoveryFormBlockFragment = groq`
  active,
  "passwordRecoveryFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${passwordRecoveryFormStringsFragment}
  },
`

const accountAddressDetailsStringsFragment = groq`
  accountAddAddressHeading,
  accountAddAddress,
  accountDefaultAddressLabel,
`

const addressFormStringsFragment = groq`
  accountAddAddressHeading,
  accountEditAddressHeading,
  accountFirstName,
  accountFirstNamePlaceholder,
  accountFirstNameMissing,
  accountLastName,
  accountLastNamePlaceholder,
  accountLastNameMissing,
  accountCompany,
  accountCompanyPlaceholder,
  accountCompanyMissing,
  accountAddressLine1,
  accountAddressLine1Placeholder,
  accountAddressLine1Missing,
  accountAddressLine2,
  accountAddressLine2Placeholder,
  accountCity,
  accountCityPlaceholder,
  accountCityMissing,
  accountCountry,
  accountCountryPlaceholder,
  accountCountryMissing,
  accountZip,
  accountZipPlaceholder,
  accountZipMissing,
  accountPhone,
  accountPhonePlaceholder,
  accountPhoneMissing,
  accountSetAsDefault,
  addressErrorMessage[] {
    ${complexPortableTextFragment}
  },
  accountAddAddress,
  accountEditAddress,
`

const accountAddressDetailsBlockFragment = groq`
  active,
  "accountAddressDetailsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountAddressDetailsStringsFragment}
  },
  "addressFormStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${addressFormStringsFragment}
  },
`

const accountDetailsStringsFragment = groq`
  accountAccountDetailsHeading,
  accountViewAddresses,
`

const accountDetailsBlockFragment = groq`
  active,
  "accountDetailsStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountDetailsStringsFragment}
  },
`

const accountHeaderStringsFragment = groq`
  accountTitle,
  accountAddressesTitle,
  accountLogoutLink,
  accountOverviewLink,
`

const accountHeaderBlockFragment = groq`
  active,
  "accountHeaderStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountHeaderStringsFragment}
  },
`

const accountOrderListStringsFragment = groq`
  accountOrderListPaymentStatusAuthorized,
  accountOrderListPaymentStatusPaid,
  accountOrderListPaymentStatusPartiallyPaid,
  accountOrderListPaymentStatusPartiallyRefunded,
  accountOrderListPaymentStatusPending,
  accountOrderListPaymentStatusRefunded,
  accountOrderListPaymentStatusVoided,
  accountOrderListFulfillmentStatusFulfilled,
  accountOrderListFulfillmentStatusInProgress,
  accountOrderListFulfillmentStatusOnHold,
  accountOrderListFulfillmentStatusOpen,
  accountOrderListFulfillmentStatusPartiallyFulfilled,
  accountOrderListFulfillmentStatusPendingFulfillment,
  accountOrderListFulfillmentStatusRestocked,
  accountOrderListFulfillmentStatusScheduled,
  accountOrderListFulfillmentStatusUnfulfilled,
  accountOrderListHeading,
  accountOrderListOrder,
  accountOrderListDate,
  accountOrderListPaymentStatus,
  accountOrderListFulfillmentStatus,
  accountOrderListTotal,
  accountOrderDetailsHeading,
  accountOrderDetailsDate,
  accountOrderDetailsProduct,
  accountOrderDetailsSku,
  accountOrderDetailsPrice,
  accountOrderDetailsQuantity,
  accountOrderDetailsTotal,
  accountOrderDetailsSubtotal,
  accountOrderDetailsShipping,
  accountOrderDetailsTax,
  accountOrderDetailsBillingAddress,
  accountOrderDetailsShippingAddress,
  accountOrderListEmpty[] {
    ${complexPortableTextFragment}
  },
`

const accountOrderListBlockFragment = groq`
  active,
  "accountOrderListStrings": *[
    _type == "strings" &&
    locale == $locale
  ][0] {
    ${accountOrderListStringsFragment}
  },
`

const productCarouselBlockFragment = groq`
  defined(title) => {
    title,
  },
  !defined(title) && useCollectionProducts && defined(collection) => {
    "title": collection->title
  },
  useCollectionProducts && defined(collection) => {
    "products": collection->products[][0...8]->{
      ${productFragment}
    },
  },
  !useCollectionProducts => {
    "products": *[_type == "product"][0...8] {
      ${productFragment}
    },
  },
`

const blogPostCardBlockFragment = groq`
  post->{
    ${blogPostWithoutBodyFragment}
  },
`

const contentCarouselBlockFragment = groq`
  items[] {
    _key,
    _type,
    _type == "freeform" => {
      ${freeformBlockFragment}
    },
    _type == "photo" => {
      ${imageFragment}
    },
    _type == "product" => {
      "product": *[
        _type == "product" &&
        _id == ^._ref
      ][0] {
        ${productFragment}
      },
    },
    _type == "blogPost" => {
      "blogPost": *[
        _type == "blogPost" &&
        _id == ^._ref
      ][0] {
        ${blogPostWithoutBodyFragment}
      },
    },
    _type == "video" => {
      ${videoFragment}
    },
  },
`

const cookieDeclarationFragment = groq`
  active,
  "cookieBotId": *[
    _type == "generalSettings" &&
    locale == $locale
  ][0].cookieBotId,
`

const iframeBlockFragment = groq`
  ...,
`

const teamMemberCardFragment = groq`
  teamMember->{
    ...,
    blogAuthor->{
      slug,
    },
    photo {
      ${imageFragment}
    },
  },
`

const linkBlockFragment = groq`
  link->{
    slug,
    date,
    "type": _type,
    queryString,
  },
  defined(text) => {
    text
  },
  !defined(text) => {
    "text": link->title
  },
  textColor,
  backgroundType,
  backgroundType == "photo" => {
    photo {
      ${imageFragment}
    },
  },
  backgroundType == "mux-video" => {
    ${muxVideoFragment}
  },
`

const nestedSimpleGridFragment = groq`
  ...,
  blocks[] {
    _key,
    _type,
    _type == "freeform" => {
      ${freeformBlockFragment}
    },
    _type == "productCard" => {
      ${productCardBlockFragment}
    },
    _type == "linkBlock" => {
      ${linkBlockFragment}
    },
    _type == "figure" => {
      ${imageFragment}
    },
    _type == "linkBlock" => {
      ${linkBlockFragment}
    },
    _type == "figure" => {
      ${imageFragment}
    },
  },
`

export const simpleGridBlockFragment = groq`
  _key,
  _type,
  _type == "freeform" => {
    ${freeformBlockFragment}
  },
  _type == "productCard" => {
    ${productCardBlockFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
  _type == "nestedSimpleGrid" => {
    ${nestedSimpleGridFragment}
  },
  _type == "linkBlock" => {
    ${linkBlockFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
  _type == "productCarousel" => {
    ${productCarouselBlockFragment}
  },
`

const baseBlockFragment = groq`
  _key,
  _type,
  _type == "freeform" => {
    ${freeformBlockFragment}
  },
  _type == "accordions" => {
    ${accordionsBlockFragment}
  },
  _type == "productCard" => {
    ${productCardBlockFragment}
  },
  _type == "signupForm" => {
    ${signupFormBlockFragment}
  },
  _type == "loginForm" => {
    ${loginFormBlockFragment}
  },
  _type == "passwordRecoveryForm" => {
    ${passwordRecoveryFormBlockFragment}
  },
  _type == "accountDetails" => {
    ${accountDetailsBlockFragment}
  },
  _type == "accountHeader" => {
    ${accountHeaderBlockFragment}
  },
  _type == "accountAddressDetails" => {
    ${accountAddressDetailsBlockFragment}
  },
  _type == "accountOrderList" => {
    ${accountOrderListBlockFragment}
  },
  _type == "video" => {
    ${videoFragment}
  },
  _type == "productCarousel" => {
    ${productCarouselBlockFragment}
  },
  _type == "newsletter" => {
    ${newsletterFragment}
  },
  _type == "blogPostCard" => {
    ${blogPostCardBlockFragment}
  },
  _type == "contentCarousel" => {
    ${contentCarouselBlockFragment}
  },
  _type == "cookieDeclaration" => {
    ${cookieDeclarationFragment}
  },
  _type == "iframe" => {
    ${iframeBlockFragment}
  },
  _type == "teamMemberCard" => {
    ${teamMemberCardFragment}
  },
  _type == "linkBlock" => {
    ${linkBlockFragment}
  },
  _type == "figure" => {
    ${imageFragment}
  },
 `

const predefinedContentBlockFragment = groq`
  "contentBlocks": contentBlock->contentBlocks[] {
    ${baseBlockFragment}
  },
`

export const blockFragment = groq`
  ${baseBlockFragment}
  _type == 'predefinedContentBlock' => {
    ${predefinedContentBlockFragment}
  },
 `
