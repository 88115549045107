import groq from 'groq'

import { simplePortableTextFragment } from './content'
import { imageFragment } from './image'

export const cartSettingsFragment = groq`
  ...,
  terms[] {
    ${simplePortableTextFragment}
  },
  paymentProviders[] {
    ${imageFragment}
  },
`

export const collectionGridCartFragment = groq`
  ...,
  filter {
    ...,
    groups[] {
      ...,
      options[]->{
        ...,
        "color": color->color,
      },
    },
  },
`

export const searchResultGridCartFragment = groq`
  paginationLimit,
`
