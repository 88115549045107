import { type SanityImageFragment } from '@data/sanity/queries/types/image'
import { type SanityModule } from '@data/sanity/queries/types/modules'
import { type SanityProductFragment } from '@data/sanity/queries/types/product'

import HeroLogo from '@components/hero-logo'
import Module from './module'

export interface ModulesProps {
  modules: SanityModule[] | null
  heroLogo?: SanityImageFragment
  collectionFeaturedProductIds?: number[]
  collectionProducts?: SanityProductFragment[]
}

const Modules = ({
  modules,
  heroLogo,
  collectionFeaturedProductIds,
  collectionProducts,
}: ModulesProps) => {
  if (!modules) {
    return null
  }

  return (
    <>
      {modules.map((module, index) => {
        if (heroLogo && index === 0) {
          return (
            <HeroLogo key={module._key} logo={heroLogo}>
              <Module
                module={module}
                collectionFeaturedProductIds={collectionFeaturedProductIds}
                collectionProducts={collectionProducts}
              />
            </HeroLogo>
          )
        }

        return (
          <Module
            key={module._key}
            module={module}
            collectionFeaturedProductIds={collectionFeaturedProductIds}
            collectionProducts={collectionProducts}
          />
        )
      })}
    </>
  )
}

export default Modules
